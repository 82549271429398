import { ROBOT_CONTENT } from '@/main';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({})
export default class NotFoundPage extends Vue {

  constructor() {
    super();
    this.$options.dunatiHeadTags = {
      title: 'Página no encontrada - Muebles de oficina | Dunati',
      metaTags: [
        /* Primaty Tags */
        {
          name: 'description',
          content: '',
        },
        {
          name: 'robot',
          content: ROBOT_CONTENT,
        },
      ],
      /*
      metaInfo: {
        link: [
          {
            rel: 'canonical',
            href: `${DUNATI_DOMAIN}/404`
          }
        ]
      }
      */
    };
  }
  public mounted() {
    this.$store.dispatch('changeMenu', 'bg1');
  }

}

